<template>
  <v-card class="rounded-lg shadow-regular">
    <v-card-text class="py-5 px-7">
      <p class="card-title mb-0 black--text">Form controls</p>
      <v-container>
        <v-row justify="space-between" class="my-2">
          <!-- Checkbox Section -->
          <v-col cols="4" class="mr-3 container-style">
            <p class="section-title mb-0 text-center">Checkbox</p>
            <v-container>
              <!-- Default checkbox -->
              <v-row
                class="d-flex flex-column container-style py-2 my-3 mx-1 px-1"
                align="center"
              >
                <div class="d-flex justify-center">
                  <p class="mt-2 mr-2 style-label">Default</p>
                  <v-btn
                    icon
                    color="caribbeanDark"
                    @click="copyToClipboard('Default Checkbox')"
                  >
                    <v-icon>mdi-code-tags</v-icon>
                  </v-btn>
                </div>
                <v-col cols="auto">
                  <!-- Checkbox -->
                  <CheckboxControl
                    label="Label"
                    v-model="defaultCheckbox"
                    @change="controlChanged('Default Checkbox')"
                  />
                  <!-- Checkbox -->
                </v-col>
              </v-row>

              <!-- Variant checkbox -->
              <v-row
                class="d-flex flex-column container-style py-2 my-3 mx-1 px-1"
                align="center"
              >
                <div class="d-flex justify-center">
                  <p class="mt-2 mr-2 style-label">Variant</p>
                  <v-btn
                    icon
                    color="caribbeanDark"
                    @click="copyToClipboard('Variant Checkbox')"
                  >
                    <v-icon>mdi-code-tags</v-icon>
                  </v-btn>
                </div>
                <v-col cols="auto">
                  <!-- Checkbox -->
                  <CheckboxControl
                    variant
                    label="Label"
                    :top-label="true"
                    v-model="variantCheckbox"
                    @change="controlChanged('Variant Checkbox')"
                  />
                  <!-- Checkbox -->
                </v-col>
              </v-row>

              <!-- Disabled checkbox -->
              <v-row
                class="d-flex flex-column container-style py-2 my-3 mx-1 px-1"
                align="center"
              >
                <div class="d-flex justify-center">
                  <p class="mt-2 mr-2 style-label">Disabled</p>
                  <v-btn
                    icon
                    color="caribbeanDark"
                    @click="copyToClipboard('Disabled Checkbox')"
                  >
                    <v-icon>mdi-code-tags</v-icon>
                  </v-btn>
                </div>
                <v-col cols="auto">
                  <!-- Checkbox -->
                  <CheckboxControl
                    label="Label"
                    v-model="disabledCheckbox"
                    :disabled="true"
                  />
                  <!-- Checkbox -->
                </v-col>
              </v-row>
            </v-container>
          </v-col>

          <!-- Switch Section -->
          <v-col cols="3" class="mr-3 container-style">
            <p class="section-title mb-0 text-center">Switch</p>
            <v-container>
              <!-- Default Switch  -->
              <v-row
                class="d-flex flex-column container-style py-2 my-3 mx-1 px-1"
                align="center"
              >
                <div class="d-flex justify-center">
                  <p class="mt-2 mr-2 style-label">Default</p>
                  <v-btn
                    icon
                    color="caribbeanDark"
                    @click="copyToClipboard('Default Switch')"
                  >
                    <v-icon>mdi-code-tags</v-icon>
                  </v-btn>
                </div>
                <v-col cols="auto">
                  <!-- Switch -->
                  <SwitchControl
                    label="Label"
                    v-model="defaultSwitch"
                    @change="controlChanged('Default Switch')"
                  />
                  <!-- Switch -->
                </v-col>
              </v-row>

              <!-- Disabled Switch -->
              <v-row
                class="d-flex flex-column container-style py-2 my-3 mx-1 px-1"
                align="center"
              >
                <div class="d-flex justify-center">
                  <p class="mt-2 mr-2 style-label">Disabled</p>
                  <v-btn
                    icon
                    color="caribbeanDark"
                    @click="copyToClipboard('Disabled Switch')"
                  >
                    <v-icon>mdi-code-tags</v-icon>
                  </v-btn>
                </div>
                <v-col cols="auto">
                  <!-- Switch -->
                  <SwitchControl
                    label="Label"
                    v-model="disabledSwitch"
                    :disabled="true"
                  />
                  <!-- Switch -->
                </v-col>
              </v-row>
            </v-container>
          </v-col>

          <!-- Radio Section -->
          <v-col cols="4" class="mr-3 container-style">
            <p class="section-title mb-0 text-center">Radio</p>
            <v-container>
              <!-- Default Radio -->
              <v-row
                class="d-flex flex-column container-style py-2 my-3 mx-1 px-1"
                align="center"
              >
                <div class="d-flex justify-center">
                  <p class="mt-2 mr-2 style-label">Default</p>
                  <v-btn
                    icon
                    color="caribbeanDark"
                    @click="copyToClipboard('Default Radio')"
                  >
                    <v-icon>mdi-code-tags</v-icon>
                  </v-btn>
                </div>
                <v-col cols="auto">
                  <!-- Radio -->
                  <RadioControl
                    label="Label"
                    v-model="radio"
                    label-true="True"
                    label-false="False"
                    @change="controlChanged('Default Radio')"
                  />
                  <!-- Radio -->
                </v-col>
              </v-row>

              <!-- Disabled Radio -->
              <v-row
                class="d-flex flex-column container-style py-2 my-3 mx-1 px-1"
                align="center"
              >
                <div class="d-flex justify-center">
                  <p class="mt-2 mr-2 style-label">Disabled</p>
                  <v-btn
                    icon
                    color="caribbeanDark"
                    @click="copyToClipboard('Disabled Radio')"
                  >
                    <v-icon>mdi-code-tags</v-icon>
                  </v-btn>
                </div>
                <v-col cols="auto">
                  <!-- Radio -->
                  <RadioControl
                    label="Label"
                    v-model="disabledRadio"
                    label-true="True"
                    label-false="False"
                    :disabled="true"
                  />
                  <!-- Radio -->
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import SwitchControl from '@/components/shared/customComponents/controls/switch-control.vue';
import CheckboxControl from '@/components/shared/customComponents/controls/checkbox-control.vue';
import RadioControl from '@/components/shared/customComponents/controls/radio-control.vue';

export default {
  name: 'FormControlWiki',
  components: {
    SwitchControl,
    CheckboxControl,
    RadioControl,
  },

  data: () => ({
    defaultCheckbox: true,
    variantCheckbox: false,
    disabledCheckbox: true,
    defaultSwitch: true,
    disabledSwitch: true,
    radio: true,
    disabledRadio: true,
  }),

  methods: {
    selectedComponentHandler(name) {
      let selectedComponent = null;

      switch (name) {
        case 'Default Checkbox':
          selectedComponent = `<CheckboxControl
              label="Label"
              v-model="defaultCheckbox"
              @change="controlChanged('Default Checkbox')"
            />`;
          break;
        case 'Variant Checkbox':
          selectedComponent = `<CheckboxControl
              variant
              label="Label"
              :top-label="true"
              v-model="variantCheckbox"
              @change="controlChanged('Variant Checkbox')"
            />`;
          break;
        case 'Disabled Checkbox':
          selectedComponent = `<CheckboxControl
              label="Label"
              v-model="disabledCheckbox"
              :disabled="true"
            />`;
          break;
        case 'Default Switch':
          selectedComponent = `<SwitchControl
              label="Label"
              v-model="defaultSwitch"
              @change="controlChanged('Default Switch')"
            />`;
          break;
        case 'Disabled Switch':
          selectedComponent = `<SwitchControl
              label="Label"
              v-model="disabledSwitch"
              :disabled="true"
            />`;
          break;
        case 'Default Radio':
          selectedComponent = `<RadioControl
              label="Label"
              v-model="radio"
              label-true="True"
              label-false="False"
              @change="controlChanged('Default Radio')"
            />`;
          break;
        case 'Disabled Radio':
          selectedComponent = `<RadioControl
              label="Label"
              v-model="disabledRadio"
              label-true="True"
              label-false="False"
              :disabled="true"
            />`;
          break;
      }
      return selectedComponent;
    },

    triggerToast(name) {
      const toastParams = {
        position: { top: true, right: true },
        timeout: 3000,
        messages: { success: `${name} copied to clipboard` },
        type: 'success',
      };

      this.$store.dispatch(
        'toastNotification/setupToastNotification',
        toastParams
      );
      this.$store.dispatch('toastNotification/showToastNotification', true);
    },

    copyToClipboard(name) {
      let component = this.selectedComponentHandler(name);
      navigator.clipboard.writeText(component);
      this.triggerToast(name);
    },

    controlChanged(name) {
      console.log(`${name} was updated!`);
    },
  },
};
</script>

<style scoped lang="scss">
.container-style {
  border: 1px dashed $grey-regular !important;
  border-radius: 5px;
}

.style-label {
  font-size: 13px !important;
}
</style>
